import request from '@/service/request'
import store from '@/store/index'
// const _permission = store.getters['login/getPermission']
const isAdmin = store.state.login.isAdmin
/* 健身房管理，分页 管理员 查看所有
*  接口地址准确 因为后端颠颠的，乱来 ，导致健身房几个page接口命名不规范
* @params
* */
export const pageGymCourtApi = (params) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin ? '/stu/gymRoom/pageManager' : '/stu/gymRoom/page'
  return request.post(url, params)
}

/* 健身房管理，list
*  接口地址准确 因为后端颠颠的，乱来 ，导致健身房几个page接口命名不规范
* @params
* */
export const listGymCourtApi = (params = {}) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin ? '/stu/gymRoom/listManager' : '/stu/gymRoom/list'
  return request.post(url, params)
}

/* 健身房管理，根据id获取
* @params
* */
export const getGymCourtByIdApi = (id) => {
  return request.post(`/stu/gymRoom/get/${id}`)
}
/* 健身房管理，删除
* @params
* */
export const delGymCourtByIdApi = (id) => {
  return request.post(`/stu/gymRoom/del/${id}`)
}

/* 健身房管理，保存
* @params
* */
export const saveGymCourtApi = (params) => {
  return request.post('/stu/gymRoom/save', params)
}

/* 健身房管理，保存
* @params
* */
export const batchBindGymCourtByUserIdApi = (params) => {
  return request.post('/stu/gymRoom/manager/saveBatch', params)
}

/* 健身房管理，批量绑定管理员
* @params {string} campusId 校区id
* @params {array} userIds 用户id list
* @params {number} userType 用户类型： 2-学生 3-教职工
* */
export const batchBindManagersByCourtApi = (params) => {
  return request.post('/stu/gymManagerUser/bindBatch', params)
}
/* 健身房管理，根据校区id获取学生核销员list
* @params {string} campusId
* */
export const listCourtStuApi = (params) => {
  return request.post('/stu/gymManagerUser/listByStu', params)
}
/* 健身房管理，根据校区id获取教职工核销员list
* @params {string} campusId
* */
export const listCourtTeacherApi = (params) => {
  return request.post('/stu/gymManagerUser/list', params)
}

/* 健身房管理，管理员list
* @params
* */
export const listGymAdminApi = (params = {}) => {
  return request.post('/stu/gymManagerUser/list', params)
}
