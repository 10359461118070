<!--  场地预约记录  -->
<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['campus','keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="健身房" label-width="90px">
          <el-select
            v-model="queryInfo.gymRoomId"
            clearable
            :disabled="!courtList.length"
            size="small">
            <el-option
              v-for="{name,id} in courtList"
              :key="id"
              :label="name"
              :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="预约开始日期" label-width="100px">
          <el-date-picker v-model="queryInfo.startDate" type="date"
                          size="small"
                          placeholder="开始日期"
                          value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="预约结束日期" label-width="100px">
          <el-date-picker v-model="queryInfo.endDate" type="date"
                          size="small"
                          placeholder="结束日期"
                          value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="是否取消">
          <el-select
            v-model="queryInfo.cancelFlag"
            clearable
            size="small">
            <el-option :value="0" label="否" />
            <el-option :value="1" label="是" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否核销">
          <el-select
            v-model="queryInfo.fixedFlag"
            clearable
            size="small">
            <el-option :value="0" label="否" />
            <el-option :value="1" label="是" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型">
          <el-select
            v-model="queryInfo.userType"
            clearable
            size="small">
            <el-option v-for="{value,label} in Object.values($store.state.systemParam.userTypeMap)" :key="value"
                       :value="value" :label="label" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%;" border stripe>
      <el-table-column prop="day" label="日期" width="120" />
      <el-table-column label="时间" width="120">
        <template v-slot="{row}">
          {{ $moment(row.startTime).format('HH:mm') }}~{{ $moment(row.endTime).format('HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column prop="gymRoomName" label="健身房" min-width="110" align="center" />
      <el-table-column prop="campusName" label="校区" width="120" />
      <el-table-column prop="stuName" label="预约人姓名" min-width="300">
        <template v-slot="{row}">
          <el-tag v-if="row.userType" :type="$store.state.systemParam.userTypeMap[row.userType].type">
            {{ $store.state.systemParam.userTypeMap[row.userType].label }}
          </el-tag>
          <span :style="{'color':row.blackFLag?'#999':''}"> {{ row.stuName || row.orderTeaName }}</span>
          <el-tag v-if="row.blackFLag" size="small" type="info" style="margin-left: 5px;">已拉黑</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="预约人学号/教工号" min-width="180">
        <template v-slot="{row}">
          {{ row.stuNo || row.orderTeaNo }}
        </template>
      </el-table-column>
      <el-table-column label="预约人电话" width="140">
        <template v-slot="{row}">
          {{ row.fixedPhone || row.orderTeaPhone }}
        </template>
      </el-table-column>
      <el-table-column label="核销人" width="200">
        <template v-slot="{row}">
          <el-tag v-if="row.fixedUserType" :type="$store.state.systemParam.userTypeMap[row.fixedUserType].type"
                  size="small">{{ $store.state.systemParam.userTypeMap[row.fixedUserType].label }}
          </el-tag>
          {{ row.teaName || row.fixedStuName }}
        </template>
      </el-table-column>
      <el-table-column prop="teaName" label="核销状态" width="90" fixed="right" align="center">
        <template v-slot="{row}">
          <el-tag v-if="row.fixedFlag" type="success">已核销</el-tag>
          <el-tag v-else-if="row.cancelFlag" type="info">已取消</el-tag>
          <el-tag v-else>待核销</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170" align="center" fixed="right">
        <template v-slot="{row}">
          <template v-if="!(row.fixedFlag||row.cancelFlag)">
            <span class="font-grey" v-if="isOutOfDate(row)">
            {{ isOutOfDate(row) }}
            </span>
            <el-button v-permission="['gym:order:cancel']" v-else
                       size="mini"
                       type="warning"
                       @click="cancel(row)"
            >取消预约
            </el-button>

            <el-popover
              placement="left"
              title="更多操作"
              width="100"
              trigger="hover">
              <el-tag v-if="row.blackFLag" type="info">已加入黑名单</el-tag>
              <el-button v-else v-permission="['gym:block']"
                         type="danger"
                         size="small"
                         @click="handleBlock(row)"
              >加入黑名单
              </el-button>
              <el-button slot="reference" size="small" icon="el-icon-more" circle />
            </el-popover>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { getSysDictionaryByKey } from '@/api/sysParams'
import {
  cancelGymOrderRecordApi,
  pageGymOrderRecordApi
} from '@/api/student-manage/gym-order-record-api'
import { listGymCourtApi } from '@/api/student-manage/gym-court-api'
import { throttle } from 'lodash'
import { blockUserGymApi } from '@/api/student-manage/gym-block-list-api'

export default {
  name: 'GymOrderRecord',
  mixins: [tableView],
  data() {
    return {
      visibleScheduleDialog: false,
      queryInfo: {
        campusId: null,
        cancelFlag: 0, // 是否取消
        fixedFlag: null, // 是否取消
        gymRoomId: null,
        status: null,
        startDate: null,
        endDate: null,
        userType: null
      },
      courtList: [], // 咨询室list
      statusList: [] // 咨询状态list
    }
  },
  watch: {
    'queryInfo.campusId': function(newVal) {
      this.queryInfo.gymRoomId = null
      if (newVal) {
        this.getRoomList()
      } else {
        this.courtList = []
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getStatusList()
    ])
    await this.renderTable(1)
  },
  methods: {
    async renderTable(pageNum) {
      await this.getTableData(pageGymOrderRecordApi, pageNum)
    },
    // 场地list
    async getRoomList() {
      try {
        const res = await listGymCourtApi({ campusId: this.queryInfo.campusId })
        this.courtList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    // 问诊状态 list
    async getStatusList() {
      try {
        const res = await getSysDictionaryByKey('psychoRecordStatus')
        this.statusList = res.data
      } catch (e) {
        console.error(e)
      }
    },
    isOutOfDate(row) {
      const _outOfDate = this.$moment(row.endTime).diff(Date.now(), 'minute') < 0
      return _outOfDate ? '--已过期--' : ''
    },
    // 加入黑名单
    handleBlock: throttle(function(row) {
      this.$confirm(`<p class="confirm-message">此操作将会把${this.$store.state.systemParam.userTypeMap[row.userType].label}-<b>${row.stuName || row.orderTeaName}【${row.stuNo || row.orderTeaNo}】 </b>移入黑名单，是否继续?</p>`, '移入黑名单确认', {
        type: 'warning',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          console.log('test', row.stuUserId)
          this.loading = true
          try {
            await blockUserGymApi(row.stuUserId)
            this.$message.success(`${row.stuName || row.orderTeaName}【${row.stuNo || row.orderTeaNo}】已被移入黑名单!`)
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        }).catch(() => {
        this.$message('已取消')
      })
    }, 1000, {}),
    cancel: throttle(function(row) {
      this.$confirm(`<p class="confirm-message">此操作将取消 ${row.stuName || row.orderTeaName}的球场预约<b class="warning">(${row.startTime}~${row.endTime})</b>，是否继续?</p>`, '取消预约', {
        type: 'warning',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          this.loading = true
          try {
            await cancelGymOrderRecordApi(row.fixedCode)
            this.$message.success('取消预约成功!')
            if (this.tableData.length - 1 === 0 && this.queryInfo.pageNum > 1) this.queryInfo.pageNum--
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    }, 1000, {})
  }
}
</script>
