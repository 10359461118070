import request from '@/service/request'

import store from '@/store/index'

const _permission = store.getters['login/getPermission']
const isAdmin = store.state.login.isAdmin
/* 健身房预约记录，分页
* 接口地址准确 因为后端颠颠的，乱来 ，导致健身房几个page接口命名不规范
* @params
* */
export const pageGymOrderRecordApi = (params) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin || _permission.includes('gym:court:edit') ? '/stu/gymStuOrderRecord/page' : '/stu/gymStuOrderRecord/pageCurrentTeacher'
  return request.post(url, params)
}

/* 健身房预约记录，list
* @params
* */
export const listGymOrderRecordApi = (params = {}) => {
  return request.post('/stu/gymStuOrderRecord/list', params)
}

/* 健身房预约记录，根据id获取
* @params
* */
export const getGymOrderRecordByIdApi = (id) => {
  return request.post(`/stu/gymStuOrderRecord/get/${id}`)
}
/* 健身房预约记录，删除
* @params
* */
export const delGymOrderRecordByIdApi = (id) => {
  return request.post(`/stu/gymStuOrderRecord/del/${id}`)
}

/* 健身房预约记录，保存
* @params
* */
export const saveGymOrderRecordApi = (params) => {
  return request.post('/stu/gymStuOrderRecord/save', params)
}

/* 健身房预约记录，取消预约
* @params
* */
export const cancelGymOrderRecordApi = (fixedCode) => {
  return request.post('/stu/gymStuOrderRecord/cancel', { fixedCode: fixedCode })
}
