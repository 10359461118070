import request from '@/service/request'

/* 健身房黑名单，分页
* 接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const pageGymBlockListApi = (params) => {
  return request.post('/stu/gymBlackUser/page', params)
}

/* 健身房黑名单 移除黑名单
* @params id
* */
export const unBlockUserGymApi = (id) => {
  return request.post(`/stu/gymBlackUser/remove/${id}`)
}

/* 健身房黑名单 加入黑名单
* @params userId 来自参数 stuUserId
* */
export const blockUserGymApi = (stuUserId) => {
  return request.post('/stu/gymBlackUser/save', { userId: stuUserId })
}
